<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ updatingItem.code_name }} - {{ $t("labels.config") }}
      <v-spacer></v-spacer>
      <v-btn color="success" @click="updateConfig" class="mr-2">
        {{ $t("labels.save") }}
      </v-btn>
      <v-btn color="error" outlined @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div class="fs-16px font-weight-medium primary--text mb-3">
        {{ $t("labels.warehouse_receipt") }}
      </div>

      <v-row>
        <v-col
          cols="12"
          md="3"
          v-for="(item, index) in receiptConfigs"
          :key="`rc_${index}`"
        >
          <v-text-field
            v-model="item.quantity"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div class="fs-16px font-weight-medium primary--text mb-3">
        {{ $t("labels.pickup") }}
      </div>
      <v-row>
        <v-col cols="12" md="3">
          <v-autocomplete
            :items="cutoffByOptions"
            v-model="cutoff_by"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.cutoff_by')"
            :placeholder="$t('labels.cutoff_by')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <date-time-picker
            v-model="cutoff_time"
            :label="$t('labels.cutoff_time')"
            :placeholder="$t('labels.cutoff_time')"
            :single-line="false"
          ></date-time-picker>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            :items="pickupCommandTypes"
            v-model="pickup_command_type"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.pickup_command_type')"
            :placeholder="$t('labels.pickup_command_type')"
            @change="onPickupTypeChanged"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-for="(item, index) in pickupConfigs"
          :key="`pc_${index}`"
        >
          <v-text-field
            v-if="pickup_command_type == 2"
            v-model="item.maxOrderPerBasket"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="item.maxItemPerBasket"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div class="fs-16px font-weight-medium primary--text mb-3">
        {{ $t("labels.stamp") }}
      </div>
      <v-row>
        <v-col
          cols="12"
          md="3"
          v-for="(item, index) in orderStampConfigs"
          :key="`rc_${index}`"
        >
          <v-autocomplete
            v-model="item.value"
            :items="orderStampOptions"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div class="fs-16px font-weight-medium primary--text mb-3">
        {{ $t("labels.stamp_footer") }}
      </div>
      <v-row>
        <v-col
          cols="12"
          md="12"
          v-for="(item, index) in orderStampFooters"
          :key="`rc_${index}`"
        >
          <v-textarea
            v-model="item.value"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div class="fs-16px font-weight-medium primary--text mb-3">
        {{ $t("labels.package") }}
      </div>
      <v-row>
        <v-col
          cols="12"
          md="3"
          v-for="(item, index) in packageConfigs"
          :key="`rc_${index}`"
        >
          <v-autocomplete
            v-model="item.size"
            :items="stampSizeptions"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="item.display"
            :placeholder="item.display"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>

  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  RECEIPT_CONFIG,
  ORDER_STAMP_CONFIG,
  ORDER_STAMP_FOOTER,
  PACKAGE_CONFIG,
  PICKUP_COMMAND_CONFIG,
  PICKUP_COMMAND_CONFIG_2,
} from "@/libs/const";

export default {
  name: "PickupConfig",
  components: {},
  props: {
    updatingItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    cutoff_by: "gi.create_timestamp",
    cutoff_time: null,
    pickup_command_type: 1,
    cutoffByOptions: [
      {
        text: "TG Tạo",
        value: "gi.create_timestamp",
      },
      {
        text: "TG Tiếp nhận",
        value: "gid.receipt_at",
      },
    ],
    pickupCommandTypes: [
      {
        text: "Option 1",
        value: 1,
      },
      {
        text: "Option 2",
        value: 2,
      },
    ],
    stampSizeptions: [
      {
        text: "100x80",
        value: "100x80",
      },
      {
        text: "100x150",
        value: "100x150",
      },
      {
        text: "100x180",
        value: "100x180",
      },
    ],
    orderStampOptions: [
      {
        text: "Custom",
        value: "custom",
      },
      {
        text: "Sàn",
        value: "e-market",
      },
    ],
    receiptConfigs: [...RECEIPT_CONFIG],
    pickupConfigs: [...PICKUP_COMMAND_CONFIG],
    orderStampConfigs: [...ORDER_STAMP_CONFIG],
    orderStampFooters: [...ORDER_STAMP_FOOTER],
    packageConfigs: [...PACKAGE_CONFIG],
  }),
  computed: {},
  watch: {},
  created() {
    if (this.updatingItem.cutoff_by) {
      this.cutoff_by = this.updatingItem.cutoff_by;
    }
    if (this.updatingItem.cutoff_time) {
      this.cutoff_time = this.updatingItem.cutoff_time;
    }
    if (this.updatingItem.pickup_command_type) {
      this.pickup_command_type = this.updatingItem.pickup_command_type;
      this.onPickupTypeChanged();
    }
    if (this.updatingItem.work_configs) {
      const workConfigs = JSON.parse(this.updatingItem.work_configs);
      const receiptConfigs = [...workConfigs].filter(
        (wk) => wk.type === "receipt"
      );
      const pickupConfigs = [...workConfigs].filter(
        (wk) => wk.type === "pickup"
      );
      const orderStampConfigs = [...workConfigs].filter(
        (wk) => wk.type === "order_stamp"
      );
      const orderStampFooters = [...workConfigs].filter(
        (wk) => wk.type === "order_stamp_footer"
      );
      const packageConfigs = [...workConfigs].filter(
        (wk) => wk.type === "package"
      );

      if (receiptConfigs && receiptConfigs.length > 0) {
        this.receiptConfigs = [...receiptConfigs];
      }
      if (pickupConfigs && pickupConfigs.length > 0) {
        this.pickupConfigs = [...pickupConfigs];
      }
      if (orderStampConfigs && orderStampConfigs.length > 0) {
        this.orderStampConfigs = [...orderStampConfigs];
      }
      if (orderStampFooters && orderStampFooters.length > 0) {
        this.orderStampFooters = [...orderStampFooters];
      }
      if (packageConfigs && packageConfigs.length > 0) {
        this.packageConfigs = [...packageConfigs];
      }
    }
  },
  mounted() {},
  methods: {
    onPickupTypeChanged() {
      if (this.pickup_command_type === 2) {
        this.pickupConfigs = [...PICKUP_COMMAND_CONFIG_2];
        return false;
      }
      this.pickupConfigs = [...PICKUP_COMMAND_CONFIG];
    },
    cancel() {
      this.$emit("cancel");
    },
    async updateConfig() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/warehouse/v1/save-pickup-config", {
          id: this.updatingItem.id,
          cutoff_by: this.cutoff_by,
          cutoff_time: this.cutoff_time,
          pickup_command_type: this.pickup_command_type,
          work_configs: [
            ...this.receiptConfigs,
            ...this.pickupConfigs,
            ...this.orderStampConfigs,
            ...this.orderStampFooters,
            ...this.packageConfigs,
          ],
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
